import httpClient from '@/libs/http-client'

// minigameSettingSave 저장
export function mgSettingSave (data) {
  const url = '/api/admin/minigame/setSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 미니게임 설정 리스트
export function mgSettingList (data) {
  const url = '/api/admin/minigame/setList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 미니게임 설정 리스트
export function betList (data) {
  const url = '/api/admin/bet/betlist'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 미니게임 배당율 설정 리스트
export function betRateList (data) {
  const url = '/api/admin/minigame/betRateList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 미니게임 배당율 설정 저장
export function betRateSave (data) {
  const url = '/api/admin/minigame/betRateSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 파워볼 결과
export function powerballResult (data) {
  const url = '/api/admin/minigame/powerballResult'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 사이트 게임별 세팅 설정 리스트
export function gameSettingList (data) {
  const url = '/api/admin/setting/game/settingList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 사이트 게임별 세팅 설정
export function gameSettingSave (data) {
  const url = '/api/admin/setting/game/settingSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function getBettingDetail (data) {
  const url = '/api/admin/bet/betDetail'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function sportBetList (data) {
  const url = '/api/admin/bet/sportBetlist'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function sportEventList (data) {
  const url = '/api/admin/bet/sportEventlist'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function sportEventDetailList (data) {
  const url = '/api/admin/bet/sportEventDetaillist'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
